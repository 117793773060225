.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.margin-align-center {
  margin: 0 auto;
}

.ant-spin-nested-loading {
  /*height: 90vh;*/
  min-height: 300px;
}

.ant-table-content {
  overflow: auto !important;
}
.clickable-icon {
  font-size: 20px;
  opacity: 0.7;
  cursor: pointer;
}
/*.ant-layout-content{*/
/*    min-height: 100%!important;*/
/*    padding-bottom: 20px;*/
/*}*/
.clickable-icon:hover {
  opacity: 1;
}
.table-filters-container {
  width: 100%;
}
.scrollable-space {
  overflow: auto;
  margin-bottom: 20px;
}
.full-width {
  width: 100%;
}
.pt-20 {
  padding-top: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-10 {
  margin-right: 10px;
}
.flex-1 {
  flex: 1;
}

.text-right {
  text-align: right;
}

.error-text {
  position: absolute;
  z-index: 1;
  top: 33px;
  left: 40px;
  padding: 0 4px;
  color: red;
  background-color: white;
  font-size: 13px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.jc-sb {
  justify-content: space-between;
}
.pl-20 {
  padding-left: 20px;
}
