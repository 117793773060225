.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}
.stack {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wrappedPets {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.wrappedAppointments {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

@media only screen and (max-width: 1600px) {
  .wrappedPets {
    grid-template-columns: repeat(4, 1fr);
  }
  :global(.calendar-page) .wrappedPets {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1400px) {
  .wrappedPets {
    grid-template-columns: repeat(4, 1fr);
  }
  :global(.calendar-page) .wrappedPets {
    grid-template-columns: repeat(3, 1fr);
  }
  .wrappedAppointments {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1150px) {
  .wrappedPets {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 900px) {
  .wrappedPets {
    grid-template-columns: repeat(3, 1fr);
  }
  :global(.calendar-page) .wrappedPets {
    grid-template-columns: repeat(2, 1fr);
  }
  .wrappedAppointments {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 650px) {
  .wrappedPets {
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: unset;
  }
  :global(.calendar-page) .wrappedPets {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 500px) {
  .wrappedAppointments {
    grid-template-columns: repeat(1, 1fr);
  }
  :global(.calendar-page) .wrappedPets {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media only screen and (max-width: 420px) {
  .wrappedPets {
    grid-template-columns: 1fr;
  }
}
.wrapModal {
  z-index: 1060 !important;
}
.modal {
  z-index: 1070 !important;
}

.petIcon {
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-F5);
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 40px 0;
}

@media only screen and (max-width: 700px) {
  .modalWrapper :global(.ant-modal) {
    height: 100% !important;
  }
  .modalWrapper :global(.ant-modal),
  .modalWrapper :global(.ant-modal-content) {
    max-width: unset !important;
    width: 100% !important;
    margin: 0 !important;
    top: 0 !important;
    padding-bottom: unset !important;
  }
  .modalWrapper {
    overflow-y: auto !important;
  }
  .modalWrapper :global(.ant-modal-body) {
    height: 100% !important;
    /*overflow-y: auto;*/
  }
}

.wrapModal :global(.ant-modal-footer),
.modalWrapper :global(.ant-modal-footer) {
  display: none;
}
