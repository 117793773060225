.providers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}
.vetCheckbox {
  margin-top: 12px;
}
.disabledVet {
  cursor: default;
  opacity: 0.7;
}
