@import "./fonts.css";

:root {
  --color-orange: #FF9A05;
  --color-orange-h: #faa426;
  --color-blue: #22A3C3;
  --color-blue-light: #eef8fb;
  --color-purple: #2D3363;
  --color-violet: #A68BFF;
  --color-violet-2: #f8f6ff;
  --color-red: #FF4B55;
  --color-red-2: #fdf1f2;
  --color-blue-op-06: rgba(34, 163, 195, 0.06);
  --color-blue-dark: #24697B;
  --color-gray-D9: #D9D9D9;
  --color-gray-F5: #F5F5F5;
  --color-gray-B8: #B8B8B8;
  --color-gray-99: #999999;
  --color-white: #FFFFFF;
  --color-black: #000000;
  --color-black-1-op-09: rgba(172, 188, 212, 0.09);
  --color-solitude-e8: #e8ecf4;
  --character-secondary-45: rgba(0, 0, 0, 0.45);
  --character-title-85: rgba(0, 0, 0, 0.85);
}
