.vet {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.vet > span {
  font-family: Roboto, serif ;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #323B4BB8;
}
.vet .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border: 1px solid #22A3C3;
  border-radius: 50%;
  background-color: #fff;
}
.icon img {
  object-fit: contain;
}
.vetName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
