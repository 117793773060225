.modal {
  display:flex;
  flex-direction: column;
  background-color: #F1F1F4;
  border-radius: 8px;
  /*overflow: hidden;*/
}
.themeLight {
  background-color: #FFF;
}
.header {
  background-color: #fff;
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #000000D9;
}
.header {
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.btnLeft {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.btnRight {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
