.popover {
  display: flex;
  flex-direction: column;
  width: 300px;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.title {
  width: 100px;
  margin-right: 10px;
}

.input,
.select {
  width: 190px;
}

.subtitle {
  font-style: italic;
}

.error {
  /* todo: move to :root variables  */
  border-color: #ff9a05 !important;
}
