.button {
  /*border-color: unset;*/
  border-style: solid;
  border-width: 1px;
  border-radius: 2px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0 !important;
}

/*region primary*/
:global(.ant-btn-primary).colorSchemeOrange {
  border-color: var(--color-orange) !important;
  background-color: var(--color-orange) !important;
  color: var(--color-white) !important;
}
:global(.ant-btn-primary).colorSchemeBlue:disabled {
  opacity: 0.5;
}
:global(.ant-btn-primary).colorSchemeBlue {
  border-color: var(--color-blue) !important;
  background-color: var(--color-blue) !important;
  color: var(--color-white) !important;
}
:global(.ant-btn-primary).colorSchemeGray {
  border-color: var(--color-gray-D9) !important;
  background-color: var(--color-gray-D9) !important;
  color: var(--color-black) !important;
}
/*endregion primary*/

/*region secondary*/
:global(.ant-btn-secondary).colorSchemeOrange {
  border-color: var(--color-orange) !important;
  background-color: var(--color-white) !important;
  color: var(--color-orange) !important;
}
:global(.ant-btn-secondary).colorSchemeBlue {
  border-color: var(--color-blue) !important;
  background-color: var(--color-white) !important;
  color: var(--color-blue) !important;
}
:global(.ant-btn-secondary).colorSchemeBlueLight {
  border-color: var(--color-blue) !important;
  background-color: var(--color-blue-light) !important;
  color: var(--character-title-85) !important;
}
:global(.ant-btn-secondary).colorSchemeGray {
  border-color: var(--color-gray-D9) !important;
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
}
/*endregion secondary*/

/*region default*/
:global(.ant-btn-default).colorSchemeBlueLight {
  background-color: var(--color-white) !important;
  color: var(--character-title-85) !important;
  opacity: 0.7;
}
/*endregion default*/

/*region link:orange*/
:global(.ant-btn-link).colorSchemeOrange {
  border: unset !important;
  border-color: unset !important;
  padding: 0 !important;
  background-color: unset !important;
  color: var(--color-orange) !important;
}
:global(.ant-btn-link).colorSchemeOrange:hover * {
  text-decoration: underline !important;
}
:global(.ant-btn-link).colorSchemeOrange:hover {
  color: var(--color-orange-h) !important;
}
:global(.ant-btn-link).colorSchemeOrange:disabled {
  opacity: 0.5;
}
/*endregion link:orange*/
