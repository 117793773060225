.content {
  padding: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 505px;
  gap: 20px;
}
.noPadding {
  padding: 26px 0px;
}
.left_column {
  /*flex: 3;*/
}
.right_column {
  /*flex: 4;*/
  /*align-items: flex-end;*/
}
@media screen and (max-width: 700px) {
  .content {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .left_column,
  .right_column {
    flex: unset;
    align-items: unset;
  }
  .left_column {
    margin-top: 16px;
  }
}
.vet {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.vet > span {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #323B4BB8;
}
.vet .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border: 1px solid #22A3C3;
  border-radius: 50%;
  background-color: #fff;
}
.icon img {
  object-fit: contain;
}

.rows {
  width: 100%;
  margin-top: 8px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill,minmax(140px, 1fr));
}

.modal {
  display:flex;
  flex-direction: column;
}
.footer {
  display: flex;
  background-color: #F1F1F4;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer > * {
  width: 300px;
  flex: 1;
}

.filters, .tsranges, .header {
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #000000D9;
}
.tsranges {
  margin-top: 4px;
}
.header {
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkboxes :global(.ant-checkbox-wrapper) {
  margin-left: 0 !important;
}
.checkBox {
  margin-top: 12px !important;
  display: flex;
  align-items: center;
}

.providerLabel {
}

.error {
  text-align: center;
  color: #FF4D4F;
}
.timeslots {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.petIcon {
  margin-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-F5);
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
}
.petIcon > span {
  max-width: 280px;
  text-align: center;
}
