.tag {
  padding: 0 8px;
  color: var(--color-blue) !important;
  font-family: Roboto;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 22px !important; /* 183.333% */
  border-radius: 2px !important;
  background: var(--color-black-1-op-09) !important;
  border-color: transparent !important;
  margin-right: 0 !important;
}
