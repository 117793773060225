.filters-wrapper {
  border: 0.1rem solid #72b4f1;
  border-radius: 0.4rem;
  padding: 1rem;
  margin-top: 2rem;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-bottom: 2rem;
}
.mb-30 {
  margin-bottom: 30px;
}
.filter-top {
  display: flex;
  gap: 1.6rem;
}

.filter-bottom {
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
}

.status-wrapper {
  border: 0.1rem solid #ccc;
  border-radius: 0.4rem;
  padding: 0.2rem 1rem;
  display: flex;
  gap: 1.6rem;
}

.popover {
  display: flex;
  flex-direction: column;
}

.single-popover {
  display: flex;
  flex-wrap: nowrap;
}

.popover-address {
  font-weight: bold;
  width: 3rem;
  margin-right: 0.5rem;
}

.listWrapper {
  display: flex;
  align-items: flex-end;
  margin: 0 10px 15px 0;
}

.totalsList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 50px;
  padding: 0;
  width: calc(100% - 90px);
  margin: 0 0 10px 0;
}

.totalsListItem {
  width: 250px;
  text-align: center;
}

.exportList {
  cursor: pointer;
  width: 90px;
  height: 32px;
}

.action-buttons-wrapper {
  display: flex;
  gap: 0.7rem;
  align-items: center;
}

.action-button {
  cursor: pointer;
  font-size: 16px;
}
