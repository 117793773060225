.wrapper {
  width: 324px;
}

.wrapper :global(.react-datepicker__triangle) {
  display: none;
}
.wrapper :global(.react-datepicker-popper) {
  padding-top: 0;
  z-index: 10;
  position: inherit !important;
  transform: none !important;
}
.wrapper :global(.react-datepicker__header) {
}
.wrapper .calendar {
  display: flex;
  justify-content: center;
  user-select: none;
}
.wrapper .section {
  width: 100%;
}

.wrapper :global(.react-datepicker__day-names) {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
  font-family: inter, serif;
  font-weight: 600;
  margin-bottom: 0.8rem;
}
.wrapper :global(.react-datepicker__day-name) {
  color: #b4b8cd;
  font-size: 1.2rem;
  text-transform: uppercase;
}
.wrapper :global(.react-datepicker__week) :global(.react-datepicker__day) {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  background: #fff;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  color: #b4b8cd;
}
.wrapper :global(.react-datepicker__day):hover {
  color: #ffffff !important;
  border: 1px solid #22a3c3;
  background: #22a3c3 !important;
}

.wrapper :global(.react-datepicker__day--selected) {
  color: #fff !important;
  border: 1px solid #22a3c3;
  background: #22a3c3 !important;
}
.wrapper :global(.react-datepicker__day--disabled) {
  color: #fff !important;
  background-color: #dbe2ee !important;
}
.wrapper :global(.react-datepicker__month-container) {
  width: 100%;
}
.wrapper :global(.react-datepicker__month) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}
.wrapper :global(.react-datepicker__week) {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}
.wrapper :global(.react-datepicker__day--disabled):hover {
  border: none;
  color: #f5f5f5 !important;
  background-color: #dbe2ee !important;
}
.themeLight :global(.react-datepicker__day) {
  border: 1px solid #eee;
}

.dp_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.8rem;
}
.calendar_header_wrapper {
}
.calendar {
  color: #2d3363;
  font-size: 2rem;
  font-family: inter, serif;
  font-weight: 600;
}
.selectedDate {
  color: #f9b057;
  font-size: 1.6rem;
  font-family: inter, serif;
  font-weight: 700;
}
.selectorWrapper {
  width: 9.4rem;
}
.month {
  color: #2d3363;
  font-family: inter, serif;
  font-weight: 600;
  font-size: 1.8rem;
}
.arrow {
  display: flex;
  align-content: center;
  align-items: center;
  color: #22a3c3;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: inherit;
}
.arrowRight {
  transform: rotate(180deg);
}
.arrowDisabled {
  opacity: 0.35;
  cursor: initial;
}
.monthSelectorWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16rem;
}
.headerBottomWrapper {
  display: flex;
  justify-content: space-between;
}

.wrapper :global(.react-datepicker__aria-live) {
  display: none !important;
}
.wrapper :global(.react-datepicker__day--today) {
  color: #000 !important;
  border: 1px solid #22a3c3;
}

.wrapper :global(div.react-datepicker__day) + :global(div.react-datepicker__day--holidays) {
  color: #22a3c3;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
}
.themeLight :global(div.react-datepicker__day) + :global(div.react-datepicker__day--holidays) {
  border: 1px solid #c2c1c1;
}
