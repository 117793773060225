.container {
  padding: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 505px;
  gap: 10px;
  flex-wrap: wrap;
}
.noPadding {
  padding: 26px 0px;
}

.filtersColumn {
  display: flex;
  flex-direction: column;
  flex: 2;
}
.timeslotsWrapper {
  flex: 4;
}
.timeslots {
  flex: 4;
  overflow-y: auto;
  max-height: 50vh;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}
.map {
  flex: 2;
  /*min-width: 400px;*/
}
.filtersColumn :global(.ant-checkbox-wrapper) {
  margin-left: 0 !important;
}
.checkBox {
  margin-top: 12px !important;
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  flex-direction: column;
  background-color: #F1F1F4;
  padding: 20px 30px;
  justify-content: center;
  align-items: center;
}
.footer > * { flex: 1; min-width: 40%; max-width: 500px; }

.monthSelectorWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 16rem;
}
.arrow {
  display:flex;
  align-content: center;
  align-items: center;
  color: #22A3C3;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: inherit;
}
.arrowRight {
  transform: rotate(180deg);
}
.arrowDisabled {
  opacity: 0.35;
  cursor: initial;
}
.selectorWrapper {
  width: 160px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sort {
  width: 60px;
}
.month {
  color: #2d3363;
  font-family: inter, serif;
  font-weight: 600;
  font-size: 1.8rem;
}

@media screen and (max-width: 1000px) {
  .container {
    flex-direction: column;
    justify-content: unset;
  }
  .map {
    min-height: 400px;
    height: 400px;
    flex: unset;
  }
  .filters {
    cursor: pointer;
    maargin-bottom: 10px;
  }
}
.filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.arrowFilter {
  display:flex;
  align-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: inherit;
  transform: rotate(-90deg);
}
.arrowFilter > * {
  fill: #80859B !important;
}
.arrowDown {
  transform: rotate(90deg);
}

.filtersContent {
  display: flex;
  flex-direction: column;
}
.filtersContentHide {
  display: none;
}
.error {
  color: #FF4D4F;
}

.petIcon {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-F5);
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px 0;
}
.petIcon > span {
  max-width: 280px;
  text-align: center;
}
