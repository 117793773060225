.h1 {}
.h2 {}
.h3 {}
.h4 {}
.h5 {
  color: var(--character-title-85);
  font-family: Roboto, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.pRegular {
  color: var(--character-title-85);
  font-family: Roboto, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.caption {
  color: var(--color-gray-B8);
  font-family: Roboto, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

.p1 {}
.p2 {}
.p3 {}

.label {
  color: var(--character-title-85);
  font-family: Roboto, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.s16w5l24 {
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.s16w6l18 {
  font-family: Roboto, serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;

}
.s14w6l18 {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}
.s14w4l24 {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.s12w5l18fI {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}
.s14w4l18fI {
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}

.s18w7l24 {
  font-family: Roboto, serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}
.s12w5l14 {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
}
.s14w5l16 {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}
.s12w2l20 {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.s12w5l18 {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
}
.s12w4l20 {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.s12w7l20 {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}
.s14w4l22 {
  font-family: Roboto, serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.s12w400l24 {
  font-family: Roboto, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
