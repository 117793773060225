@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-display: auto;
  src: local(Roboto), url('../common/fonts/Roboto-Thin.ttf') format('trueType');
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: local(Roboto), url('../common/fonts/Roboto-Light.ttf') format('trueType');
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local(Roboto), url('../common/fonts/Roboto-Regular.ttf') format('trueType');
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: local(Roboto), url('../common/fonts/Roboto-Medium.ttf') format('trueType');
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local(Roboto), url('../common/fonts/Roboto-Bold.ttf') format('trueType');
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: local(Roboto), url('../common/fonts/Roboto-Black.ttf') format('trueType');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local(Inter), url('../common/fonts/Inter-Regular.ttf') format('trueType');
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-display: auto;
  src: local(Inter), url('../common/fonts/Inter-Medium.ttf') format('trueType');
}
