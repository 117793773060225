.container {
  display: flex;
  align-items: baseline;
  margin-bottom: 14px;
}

.container :global(.ant-checkbox-checked::after) {
  height: 20px;
  width: 20px;
}
.container
  :global(.ant-checkbox:hover::after)
  .container
  :global(.ant-checkbox-wrapper:hover .ant-checkbox::after) {
}
.container :global(.ant-checkbox-inner) {
  border-color: #acbcd4 !important; /* var(--color-blue) !important;*/
  height: 20px;
  width: 20px;
}
.container :global(.ant-checkbox-checked .ant-checkbox-inner::after) {
  width: 8px;
  height: 11px;
}
.container :global(.ant-checkbox-indeterminate .ant-checkbox-inner::after) {
  width: 12px;
  height: 12px;
  border-color: var(--color-blue) !important;
}
.container :global(.ant-checkbox-indeterminate .ant-checkbox-inner::after),
.container :global(.ant-checkbox-checked .ant-checkbox-inner) {
  background-color: var(--color-blue);
  border-color: var(--color-blue) !important;
}
