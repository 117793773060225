.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;
}
.title {
  flex: 1;
  text-align: center;
  margin-left: 8px;
}
:global(.ant-input).textArea {
  height: 100px !important;
  resize: none;
}
.mb12 {
  margin-bottom: 12px;
}
.buttons {
  margin-top: 32px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.container :global(.ant-upload-list) {
  display: flex !important;
  flex-wrap: wrap !important;;
  gap: 10px !important;;
  margin-top: 14px;
}

.container :global(.ant-upload-list):before {
  content: none !important;
}
.container :global(.ant-upload-list):after {
  content: none !important;
}


.file {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  border-radius: 3px;
  background: var(--color-black-1-op-09);
}
.fileName {
  overflow: hidden;
}
@media screen and (max-width: 699px) {
  .file, .fileName,
  .container :global(.ant-upload-list),
  .container :global(.ant-upload-list-text-container) {
    width: 100%
  }
}
.pet {
  margin: 12px 0;
}

@media screen and (max-width: 699px) {
  .container {
    min-height: 100vh;
  }
}
