.timeslotItem {
  border: 1px solid #D9D9D9;
  display: flex;
  position: relative;
  padding: 17px 10px;
  background: #fff;
}
.timeslotItem > * {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 7px;
  cursor: pointer;
}
.timeslotItem:hover,
.activeTimeslotItem {
  border-color: #22A3C3;
}
.disabledTimeslotItem {
  cursor: default;
}
.distanceContainer {
  align-items: center;
  flex: 1;
  border-right: 1px solid #F0F0F0;
}
.dateContainer {
  flex: 2;
}
.vetContainer {
  flex: 2;
  border-left: 1px solid #F0F0F0;
}

.tsName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 140px;
}
.vetLabel {
  margin-bottom: 0px;
}

.marks {
  min-height: unset;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
}
.markFull, .markLocked {
  padding: 2px 8px 2px 8px;
  border-radius: 1px 0px 0px 0px;
}
.markFull {
  background: #FF9A0529;
  color: #FF9A05;
}
.markLocked {
  background: #FF4B5529;
  color: #FF4B55;
}

/* calendar */
.timeslot, .timeslots {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.timeslot {
  justify-content: center;
  align-items: center;
  color: #22A3C3;
  background-color: #fff;
  border-radius: 6px;
  padding: 6px;
  cursor: pointer;
}
.timeslot span {
  margin: 0;
  padding: 0;
}
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 135px;
}
.selected {
  background-color: #22A3C3;
  color: #fff;
}
.disabled {
  background-color: #fff;
  color: #B4B8CD;
  cursor: default;
}
.themeLight {
  border: 1px solid #eee;
}
.themeLight.selected {
  border: 1px solid #22A3C3;
}
