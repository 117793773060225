.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;
  position: relative;
}
.bottom {
  height: 14px;
}
.error {
  padding-top: 1px;
  display: block;
  color: var(--color-red);
}
.bottomRight {
  display: block;
  text-align: right;
  margin-top: -8px;
  position: absolute;
  right: 4px;
  background-color: var(--color-white);
}
.topLeft {
  margin-bottom: 2px;
}
